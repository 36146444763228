.accordion{
    display: block;
    background-color: #F2E5D1;
    border-radius: 5px 5px 5px 5px;
    margin-block: 2em 2em;
}

.accordion-header{
    display: flex;
    color: black;
    transition: 0.8s;
    padding: 1em 1em;
    font-family: Raleway;
    font-weight: bolder;
}

.accordion-header p {
    font-weight: bolder;
    margin: 0;
}

.accordion-content {
    display: flex;
    color: #3C3C43;
    margin-left: 3em;
    font-family: Raleway;
    animation: accord 0.5s ease;
}

.accordion-content-none {
    display: flex;
    color: #3C3C43;
    margin-left: 3em;
    font-family: Raleway;
    display: none;
    animation: accord 0.5s ease;
}

@keyframes accord {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes accordionClick {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion.clicked {
    animation: accordionClick 0.3s ease;
}

.div--chevron{
    display: flex;
    width: 2em;
    align-items: center;
    justify-content: center;
}

.chevron{
    font-size: 16px;
}

.chevron.rotate{
    transform: rotate(90deg);
    height: 1em;
    width: 1em;
}

@media screen and (min-width: 712px) {

    .accordion-header{
        font-size: 6px;
        justify-content: space-between;
    }

    .accordion-content{
        margin-left: 0.5em;
    }

    .chevron{
        font-size: 16px;
    }

    .chevron.rotate{
        transform: rotate(90deg);
        font-size: 16px;
    }
}

@media screen and (max-width:711px) {
    .accordion-header{
        font-size: 6px;
        justify-content: space-between;
    }

    .accordion-content{
        margin-left: 0.5em;
    }

    .chevron{
        font-size: 16px;
    }

    .chevron.rotate{
        transform: rotate(90deg);
        font-size: 16px;
    }
}