body {
  font-family: Raleway;
  overflow-x: hidden;
}

div.img2 h2 {
  padding-top: 0.5em;
  max-width: 22em;
}

div.img2 p {
  max-width: 35em;
}

.img1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 85vh;
  background-image: url("../../image/autres/Landing/Rectangle\ 101.png");
}

div.cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.card {
  width: 15em;
  height: 25em;
  display: inline-block;
  margin: 3em;
  border-radius: 10px;
}

div.card-img {
  height: 20em;
}

.img2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 80vh;
  padding: 2em;
  width: 100vw;
  background-size: cover;
  background-image: url("../../image/autres/Landing/Rectangle\ 49.png"),
    url("../../image/autres/Landing/Rectangle\ 167.png");
}

.spacing {
  margin: 10px;
}

.img3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 75em;
  /*77.3em*/
  padding: 2em;
  background-image: url("../../image/autres/Landing/Rectangle\ 51.png");
}

.img3 h1 {
  padding: 10px !important;
}

.img3 .container {
  display: flex;
  flex-direction: column;
  margin-top: -5em;
  align-items: center;
  justify-content: center;
}

.img3 .container h1 {
  margin-top: 2em;
}

.slide-feuille-1 {
  animation: slide-feuille-1 1.7s forwards ease;
}
.slide-feuille-2 {
  animation: slide-feuille-2 0.7s forwards ease-out;
}

.img-feuille {
  display: block;
  position: absolute;
  width: 13em;
  height: 25em;
  top: 91.5em;
  right: 0;
  /*right: -8.1em;*/
  background-size: contain;
  background-image: url("../../image/autres/Leaves/Leaves\ 1.png");
}

.img-feuille2 {
  display: block;
  position: absolute;
  width: 13em;
  height: 25em;
  top: 155em;
  left: 0;
  z-index: 2;
  /*right: -8.1em;*/
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../image/autres/Leaves/Leaves\ 2\ \(1\).png");
}

@keyframes slide-feuille-2 {
  from {
    transform: translateX(0);
    opacity: 0;
  }
  to {
    transform: translateX(100px);
    opacity: 1;
  }
}
@keyframes slide-feuille-1 {
  from {
    transform: translateX(350px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

div img .img-font {
  background-size: cover;
  width: 90%;
  overflow: 0;
}

.block {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  background-image: url("../../image/autres/Landing/Rectangle\ 53.png");
}
.slider-container {
  width: 90vw;
}
.block p {
  padding: 25px !important;
  font-family: Raleway;
}

.slide-left {
  animation: slide-left 1s forwards ease-out;
}

.slide-right {
  animation: slide-right 1s forwards ease-out;
}
.slide-upCrd {
  animation: slide-upCrd 0.7s forwards ease-out;
}

p.slide-left {
  animation-delay: 0.5s;
  opacity: 0;
}

button.slide-left {
  animation-delay: 1s;
  opacity: 0;
}

.cards {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  padding: 0 2em;
  /* Add padding to create space between the cards */
}

.valeurs {
  padding-top: 5em;
  display: none;
}

i.custom-prev-button:hover {
  color: white;
}

i.custom-next-button:hover {
  color: white;
}

div.swiper-button-next {
  color: white;
}

@keyframes slide-upCrd {
  0% {
    transform: translateY(90px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    transform: translate(100px);
    opacity: 0;
  }

  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translate(0);
    opacity: 0;
  }

  100% {
    transform: translate(100px);
    opacity: 1;
  }
}

@media screen and (max-width: 920px) {
  div.img1 {
    height: 85vh;
  }

  div.img3 {
    height: 103em;
  }

  div.img3 h1 {
    margin-top: 2em;
  }
}

@media screen and (max-width: 720px) {
  div.img1 {
    height: 70vh;
  }

  .slider-container {
    width: 83vw;
  }
  .img-feuille {
    display: none;
  }
  .img-feuille2 {
    display: none;
  }

  .block {
    padding: 0;
    width: 100vw;
  }
  .block p {
    text-align: start;
  }
  div.img2 {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }

  div.img2 .container {
    height: 36em;
    max-width: 100%;
    padding: 3em;
  }

  div.img3 {
    height: 80em;
  }
  .img3 .container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    align-items: center;
    justify-content: center;
  }
  .engagements {
    padding-top: 2em;
  }

  .valeurs {
    padding-top: 0em;
  }

  .block {
    height: auto;
    padding: 0;
  }

  .img2 button {
    font-size: 14px;
  }

  .img2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-image: url("../../image/autres/Landing/Rectangle\ 49.png"),
      url("../../image/autres/Landing/Rectangle\ 167.png");
  }

  .block p {
    font-size: 14px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
    box-shadow: none;
  }
  .custom-prev-button {
    display: none;
  }
  .custom-next-button {
    display: none;
  }

  div.swiper-pagination {
    padding-top: 3em !important;
  }

  span.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background: white;
  }

  @keyframes slide-left {
    0% {
      transform: translate(70px);
      opacity: 0;
    }
    100% {
      transform: translate(0px);
      opacity: 1;
    }
  }

  @keyframes slide-right {
    0% {
      transform: translate(0);
      opacity: 0;
    }

    100% {
      transform: translate(70px);
      opacity: 1;
    }
  }
}

@media screen and (max-width: 420px) {
  div.img1 {
    height: 29em;
  }

  div.card {
    height: 23em;
  }

  div.img2 {
    width: auto;
    object-fit: cover;
  }

  div.img2 .container {
    max-width: 100%;
    padding: 3em;
  }

  div.img3 {
    height: 85em;
  }

  .block {
    height: auto;
  }
}

@media screen and (min-width: 720px) {
  div.swiper-pagination {
    display: none;
  }

  span.swiper-pagination-bullet {
    display: none;
  }
}
