.products-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: -6.5em;
  background-image: url(../../../image/autres/Landing/Rectangle\ 6.png),
    url(../../../image/autres/Fonds/BG_NosProduits.webp);
  height: 99vh;
  width: 100%;
  background-position: bottom;
  background-size: 100% 100%;
}

.products a {
  text-decoration: none;
  font-family: inherit;
  color: inherit;
}

.products {
  font-family: Raleway;
  background-image: url(../../../image/autres/Landing/Rectangle\ 51.png);
}

.products--products--list,
.products--products--list--test {
  min-height: 50vh;
}

.header--text {
  font-family: Canela deck trial !important;
}

.scroll--down {
  position: absolute;
  bottom: 0;
  margin: auto;
  margin-block: 2rem 2rem;
}

.products .fa-chevron-down {
  text-align: center;
  animation-name: scrollDown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes scrollDown {
  0% {
    margin-top: 10px;
    opacity: 1;
  }
  50% {
    margin-top: 40px;
    font-size: 50px;
    opacity: 0.7;
  }
  100% {
    margin-top: 50px;
    font-size: 40px;
    opacity: 0.5;
  }
}

@media (max-width: 600px) {
  .products-header {
    background-size: cover;
  }

  .header--text {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 40px;
  }

  .products--body {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .products {
    background-color: beige;
  }

  .products--products--list,
  .products-filter--bar,
  .products--products--list--test {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
}

@media (min-width: 600px) {
  .header--text {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 64px;
  }

  .products--body {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .products {
    background-color: beige;
  }

  .products--products--list {
    margin-bottom: 5rem;
  }

  .products--products--list,
  .products-filter--bar {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

  .products--products--list--test {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 999px) {
  .leave--up,
  .leave--down {
    display: none;
  }
}
@media (min-width: 1000px) {
  .products-header {
    position: relative;
  }
  .leave--up {
    position: absolute;
    display: block;
    height: 30rem;
    width: 20rem;
    right: 0;
    top: 100vh;
    z-index: 1;
  }

  .products .fa-chevron-down {
    font-size: 60px;
  }

  .products-footer {
    position: relative;
  }

  .leave--down {
    position: absolute;
    display: block;
    height: 30rem;
    width: 20rem;
    left: 0;
    top: -40rem;
  }

  .products--products--list,
  .products--products--list--test {
    position: relative;
    z-index: 2;
  }
}
