.filter--bar--alphabet:hover {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.filter--and--search--bar {
  position: relative;
  z-index: 5;
}

.products--list--suggestion {
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.74);
  color: rgba(5, 5, 5, 0.959);
  margin-top: 5px;
  width: 25rem;
  margin-left: 1rem;
}

.--card--suggestion {
  display: flex;
  flex-direction: row;
  padding-inline: 5px 5px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.637);
  padding-block: 5px 5px;
}

.--card--suggestion a {
  display: flex;
  flex-wrap: wrap;
}

.--card--suggestion a:hover {
  display: flex;
  flex-wrap: wrap;
  text-decoration: underline;
  font-weight: bold;
}

.--card--suggestion--name {
  display: flex;
  align-items: flex-end;
  margin-inline: 1rem 0rem;
}

.--image--suggestion {
  width: 2rem;
  height: 2rem;
  background-size: contain;
}

@media (max-width: 669px) {
  .filter--and--search--bar {
    background-color: rgba(0, 0, 0, 0.53);
    padding: 1rem;
  }

  .search--bar {
    display: flex;
    width: 100%;
  }
  .search--bar--search {
    width: 60%;
  }

  .products--list--suggestion {
    width: 18rem !important;
  }

  .search--bar--button {
    background-color: red;
    border-radius: 5px;
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding-inline: 3rem 3rem;
    margin-left: 0.5rem;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--button:hover {
    background-color: rgb(86, 1, 1);
  }

  .select--bar {
    display: none;
  }

  .filter--bar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    color: red;
    margin-top: 0.5rem;
  }
}

@media (min-width: 670px) {
  .filter--and--search--bar {
    width: 35rem;
    margin-block: 1.5rem 0rem !important;
  }
  .search--bar--line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    background-color: rgba(0, 0, 0, 0.53);
    border-radius: 8px;
    padding-inline: 0.75rem 0.75rem;
  }

  .search--bar {
    display: flex;
    flex-direction: row;
  }

  .search--bar--button,
  .search--bar--search,
  .select--bar {
    height: 2rem;
  }

  .select--bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: grey;
    letter-spacing: 1px;
    border-radius: 5px;
    padding-inline: 0.5rem 0.5rem;
    width: 27%;
    font-size: 12px;
  }

  .select--bar--select {
    height: 100%;
    outline: none;
    border: none;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: white;
  }

  .select--bar--select:focus-visible {
    outline: none;
  }

  .search--bar--search {
    border-radius: 8px;
    border-color: transparent;
    width: 40%;
    padding-inline: 3px 3px;
    font-size: 14px;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--search:focus-visible {
    outline: none;
  }

  .search--bar--button {
    background-color: red;
    border-radius: 5px;
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding-inline: 3rem 3rem;
    margin-left: 0.5rem;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--button:hover {
    background-color: rgb(86, 1, 1);
  }

  .filter--bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: red;
    margin-top: 0.5rem;
  }
}

@media (min-width: 1000px) {
  .filter--and--search--bar {
    width: 54rem;
    margin-block: 2rem 3.5rem;
  }
  .search--bar--line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0.53);
    border-radius: 10px;
    padding-inline: 1rem 1rem;
  }

  .search--bar {
    display: flex;
    flex-direction: row;
  }

  .search--bar--button,
  .search--bar--search,
  .select--bar {
    height: 2.5rem;
  }

  .select--bar {
    background-color: white;
    color: grey;
    letter-spacing: 1px;
    border-radius: 5px;
    padding-inline: 0.5rem 0.5rem;
    font-size: 16px;
    width: fit-content;
  }

  .select--bar--select {
    height: 100%;
    width: fit-content;
    outline: none;
    border: none;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: white;
  }

  .select--bar--select:focus-visible {
    outline: none;
  }

  .search--bar--search {
    border-radius: 8px;
    width: fit-content;
    border-color: transparent;
    padding-inline: 10px 10px;
    font-size: 18px;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--search:focus-visible {
    outline: none;
  }

  .search--bar--button {
    background-color: red;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 2rem 2rem;
    margin-left: 0.5rem;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .filter--bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: red;
    margin-top: 0.5rem;
    margin-inline: 1rem 1rem;
  }

  .filter--bar--alphabet:hover {
    text-decoration: underline;
    font-weight: 500;
  }
}

@media (min-width: 1150px) {
  .filter--and--search--bar {
    width: 62rem;
    margin-block: 2rem 3.5rem;
  }
  .search--bar--line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0.53);
    border-radius: 10px;
    padding-inline: 1rem 1rem;
  }
  .search--bar {
    display: flex;
    flex-direction: row;
  }

  .search--bar--button,
  .search--bar--search,
  .select--bar {
    height: 2.5rem;
  }

  .select--bar {
    background-color: white;
    color: grey;
    letter-spacing: 1px;
    border-radius: 5px;
    padding-inline: 0.5rem 0.5rem;
    font-size: 16px;
    width: fit-content;
  }

  .select--bar--select {
    height: 100%;
    outline: none;
    border: none;
    width: fit-content;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: white;
  }

  .select--bar--select:focus-visible {
    outline: none;
  }

  .search--bar--search {
    border-radius: 8px;
    width: fit-content;
    border-color: transparent;
    padding-inline: 10px 10px;
    font-size: 18px;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--search:focus-visible {
    outline: none;
  }

  .search--bar--button {
    background-color: red;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 2rem 2rem;
    margin-left: 0.5rem;
    width: fit-content;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .filter--bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: red;
    margin-top: 0.5rem;
  }

  .filter--bar--alphabet:hover {
    text-decoration: underline;
    font-weight: 500;
  }
}

@media (min-width: 1300px) {
  .filter--and--search--bar {
    width: 76rem;
    margin-block: 2rem 3.5rem !important;
  }
  .search--bar--line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0.53);
    border-radius: 10px;
    padding-inline: 1rem 1rem;
  }
  .search--bar {
    display: flex;
    flex-direction: row;
  }

  .search--bar--button,
  .search--bar--search,
  .select--bar {
    height: 2.5rem;
  }

  .select--bar {
    background-color: white;
    color: grey;
    letter-spacing: 1px;
    border-radius: 5px;
    padding-inline: 0.5rem 0.5rem;
    font-size: 16px;
    width: fit-content;
  }

  .select--bar--select {
    height: 100%;
    outline: none;
    border: none;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: white;
  }

  .select--bar--select:focus-visible {
    outline: none;
  }

  .search--bar--search {
    border-radius: 8px;
    width: fit-content;
    border-color: transparent;
    padding-inline: 10px 10px;
    font-size: 18px;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .search--bar--search:focus-visible {
    outline: none;
  }

  .search--bar--button {
    background-color: red;
    border-radius: 5px;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding-inline: 2rem 2rem;
    margin-left: 0.5rem;
    box-shadow: 1px 1px 2px rgb(53, 52, 52), -1px -1px 2px rgb(53, 52, 52);
  }

  .filter--bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: red;
    margin-top: 0.5rem;
  }

  .filter--bar--alphabet:hover {
    text-decoration: underline;
    font-weight: 500;
  }
}
