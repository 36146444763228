.one--product--display {
  padding-block: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(../../image/autres/Landing/Rectangle\ 51.png);
}

.random--product--container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 60%;
  background: rgba(0, 0, 0, 0.149);
  border-radius: 10px;
  overflow-y: hidden;
  margin-block: 7vh 3vh;
  width: 90vw;
}

.single--product--left {
  width: 30%;
  background-size: 100% 100%;
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.button--product a .btn {
  margin-top: 1em;
  margin-bottom: 2em;
  background: #f2e5d1;
}

@media (max-width: 650px) {
  .single--product--conditionnement {
    display: none;
  }
}

@media (max-width: 724px) {
  .one--product--display {
    margin-block: 2rem 2rem;
  }

  .single--product {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .single--product h2 {
    margin-bottom: 3vw;
  }

  .single--product--container {
    background: #121212;
    width: 85vw;
    margin-block: 1rem 1rem;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    box-shadow: 1px 2px 2px black;
    border-radius: 5px;
  }

  .line-red {
    display: none;
  }

  .single--product--right {
    width: 65%;
    padding-block: 1rem 1rem;
    padding-inline: 2vw 2vw;
  }

  .single--product--right p {
    margin-bottom: 0.3rem;
  }
}

@media (min-width: 725px) {
  .single--product {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .single--product h2 {
    margin-bottom: 3vw;
  }

  .single--product--container {
    background: #121212;
    width: 85vw;
    margin-block: 1rem 1rem;
    display: flex;
    height: fit-content;
    box-shadow: 1px 2px 2px black;
    border-radius: 5px;
  }

  .single--product--left {
    animation-name: left;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes left {
    from {
      opacity: 0;
      margin-block: 15rem 15rem;
    }
    to {
      opacity: 1;
    }
  }

  .line-red {
    background-color: red;
    height: 5re;
    width: 1px;
    margin-inline: 4vw 4vw;
    margin-block: 15rem 15rem;
    animation-name: sweep;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes sweep {
    from {
      margin-block: 15rem 15rem;
      opacity: 0;
    }
    to {
      margin-block: 2.5rem 2.5rem;
      opacity: 1;
    }
  }

  .single--product--right {
    width: 60%;
    padding-block: 1rem 1rem;
    padding-inline: 0 2vw;
    animation-name: right;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes right {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (min-width: 1000px) {
  .single--product--left {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .gallery {
    position: absolute;
    bottom: 0;
    height: 7rem;
    padding-inline: 0.1rem 0.1rem;
    display: flex;
    align-items: center;
    overflow-x: scroll; /* Enable horizontal scrolling */
    white-space: nowrap;
    width: 100%;
  }

  .gallery-container {
    display: flex;
    transition: transform 0.3s ease; /* Smooth transition for sliding */
  }

  .gallery::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 10px;
  }

  .gallery::-webkit-scrollbar-thumb {
    background-color: rgba(5, 5, 5, 0.7); /* Change the color of the thumb */
    border-radius: 6px; /* Rounded corners for the thumb */
  }

  /* Style the scrollbar track (the background) */
  .gallery::-webkit-scrollbar-track {
    background-color: rgba(5, 5, 5, 0.7); /* Change the color of the track */
  }

  /* Style the scrollbar track when the thumb is being dragged */
  .gallery::-webkit-scrollbar-thumb:hover {
    background-color: rgba(5, 5, 5, 0.9); /* Color on hover */
  }

  .photo {
    height: 5rem;
    width: 5rem;
    background-size: 100% 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-inline: 2px;
  }
}
