.snippet {
  position: relative;
  background: #fff;
  padding: 32px 5%;
  box-shadow: 0 4px 12px -2px rgba(0, 32, 128, 0.1),
    0 0 0 1px rgba(60, 80, 120, 0.1);
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snippet::before {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 8px;
  content: attr(data-title);
  font-size: 12px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 10px;
  line-height: 20px;
}

.snippet:hover {
  cursor: pointer;
  outline: 2px solid rgb(255, 25, 100);
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 100px 100px;
  overflow: hidden;
}

.filter-contrast {
  filter: contrast(5);
  background-color: white;
}

/*DOT REVOLUTION*/

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9991px -48px 0 0 #9880ff, 9991px 0 0 0 #9880ff,
    10007px 0 0 0 #9880ff;
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9950px -48px 0 0 #9880ff, 9950px 0 0 0 #9880ff,
      10050px 0 0 0 #9880ff;
  }
  8.333% {
    box-shadow: 10050px -48px 0 0 #9880ff, 9950px 0 0 0 #9880ff,
      10050px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 10050px -48px 0 0 #9880ff, 9950px -48px 0 0 #9880ff,
      10007px 0 0 0 #9880ff;
  }
  25% {
    box-shadow: 10050px -48px 0 0 #9880ff, 9950px -48px 0 0 #9880ff,
      9950px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 10050px 0 0 0 #9880ff, 9950px -48px 0 0 #9880ff,
      9950px 0 0 0 #9880ff;
  }
  41.667% {
    box-shadow: 10050px 0 0 0 #9880ff, 10050px -48px 0 0 #9880ff,
      9950px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 10050px 0 0 0 #9880ff, 10050px -48px 0 0 #9880ff,
      9950px -16px 0 0 #9880ff;
  }
  58.333% {
    box-shadow: 9950px 0 0 0 #9880ff, 10050px -48px 0 0 #9880ff,
      9950px -48px 0 0 #9880ff;
  }
  66.666% {
    box-shadow: 9950px 0 0 0 #9880ff, 10050px 0 0 0 #9880ff,
      9950px -48px 0 0 #9880ff;
  }
  75% {
    box-shadow: 9950px 0 0 0 #9880ff, 10050px 0 0 0 #9880ff,
      10050px -48px 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9950px -48px 0 0 #9880ff, 10050px 0 0 0 #9880ff,
      10050px -48px 0 0 #9880ff;
  }
  91.667% {
    box-shadow: 9950px -48px 0 0 #9880ff, 9950px 0 0 0 #9880ff,
      10050px -48px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9950px -48px 0 0 #9880ff, 9950px 0 0 0 #9880ff,
      10050px 0 0 0 #9880ff;
  }
}
