/* Caroussel.css */
.carousel-container {
  width: 110vw;
  height: 83vh;

  position: relative;
  overflow: hidden;
  background: black;
}

#bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  object-fit: cover;
}
.contenu-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0em !important;
}

.carousel-container .carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.carousel-container .carousel button {
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 10px;
}
.carousel-container .carousel-indicators {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

span.carousel-control-next-icon {
  display: none;
}

span.carousel-control-prev-icon {
  display: none;
}

.savoir-plus:hover {
  color: #f2e5d1;
  border: 1px solid #f2e5d1;
  background: #000;
  transition: 0.3s all;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7);
  /* Adjust the overlay color and transparency */
  padding: 20px;
  border-radius: 10px;
  bottom: 10% !important;
  transform: translateX(-50%);
  max-width: 80%;
  /* Adjust the maximum width as needed */
  color: #fff;
}

.img1-carousel {
  background-size: cover;
  background-image: url("../../image/autres/Landing/Rectangle\ 49.png");
}

.carousel-caption h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.carousel-caption p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.carousel-caption .btn {
  font-size: 1rem;
}

@media screen and (max-width: 720px) {
  body p {
    font-size: 14px;
  }
  body {
    font-size: 15px;
  }
  .carousel-container {
    height: 80vh;
  }
  .contenu-carousel {
    padding-top: 0em !important;
  }
  .contenu-carousel span {
    font-size: 14px;
  }
}
