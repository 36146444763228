.products--list {
  background-color: rgba(0, 0, 0, 0.53);
  border-radius: 10px;
  margin-block: 1rem 1rem;
}

@media (max-width: 359px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 20rem;
  }
}

@media (min-width: 360px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 22rem;
  }
}

@media (min-width: 425px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 26rem;
  }
}

@media (min-width: 450px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 27rem;
  }
}

@media (min-width: 670px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 42rem;
  }
}

@media (min-width: 1000px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 56rem;
  }
}

@media (min-width: 1150px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 64.8rem;
  }
}

@media (min-width: 1400px) {
  .products--list {
    display: flex;
    flex-flow: row wrap;
    width: 80rem;
  }
}
