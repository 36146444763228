.card--image--hover {
  width: 100%;
}

@media (max-width: 359px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11.5rem;
    width: 9rem;
    background-color: rgb(5, 34, 34);
    border-radius: 6px;
    margin: 3px;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.5);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    padding-inline: 2px 2px;
    border-radius: 3px;
    margin-inline: 3px 3px;
    text-shadow: 1px 1px 2px rgba(182, 177, 177, 0.692);
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 11.5rem;
    width: 9rem;
    background-color: rgb(5, 34, 34);
    border-radius: 6px;
    margin: 3px;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
  }

  .card--body--hover {
    display: none;
  }

  .card--title--hover {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding-inline: 2px 2px;
    border-radius: 3px;
    margin-inline: 3px 3px;
    text-shadow: 1px 1px 2px rgba(122, 121, 121, 0.747);
  }
}

@media (min-width: 360px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13.5rem;
    width: 10rem;
    background-color: rgb(5, 34, 34);
    border-radius: 7px;
    margin: 3px;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.5);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 13.5rem;
    width: 10rem;
    background-color: black;
    border-radius: 7px;
    margin: 3px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    background-image: none;
  }

  .card--title--hover {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-inline: 2px 2px;
    border-radius: 3px;
    margin-inline: 3px 3px;
    text-shadow: 1px 1px 2px rgba(122, 121, 121, 0.747);
  }

  .card--body--hover {
    display: none;
  }
}

@media (min-width: 425px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15.5rem;
    width: 12rem;
    background-color: rgb(5, 34, 34);
    border-radius: 7px;
    margin: 3px;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.55);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15.5rem;
    width: 12rem;
    background-color: black;
    border-radius: 7px;
    margin: 3px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    background-image: none;
  }

  .card--title--hover {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-inline: 2px 2px;
    border-radius: 3px;
    margin-inline: 3px 3px;
    text-shadow: 1px 1px 2px rgba(122, 121, 121, 0.747);
  }

  .card--body--hover {
    display: none;
  }
}

@media (min-width: 450px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10.5rem;
    width: 8rem;
    background-color: rgb(5, 34, 34);
    border-radius: 7px;
    margin: 3px;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.45);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10.5rem;
    width: 8rem;
    background-color: black;
    border-radius: 7px;
    margin: 3px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 0.5rem 0.5rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    background-image: none;
  }

  .card--title--hover {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding-inline: 2px 2px;
    border-radius: 3px;
    margin-inline: 3px 3px;
    text-shadow: 1px 1px 2px rgba(122, 121, 121, 0.747);
  }

  .card--body--hover {
    display: none;
  }
}

@media (min-width: 670px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
    width: 12rem;
    background-color: rgb(5, 34, 34);
    border-radius: 7px;
    margin: 3px;
    margin-inline: 1rem 1rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.5);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    flex-direction: column;
    height: 16rem;
    width: 12rem;
    background-color: black;
    border-radius: 7px;
    margin: 3px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 1rem 1rem;
    margin-block: 15px 15px;
    background-image: none;
  }

  .card--title--hover {
    display: none;
  }

  .card--image--hover {
    height: 40%;
    background-color: rgb(5, 34, 34);
    border-start-start-radius: inherit;
    border-start-end-radius: inherit;
    background-size: 100% 100%;
    background-position: right;
  }

  .card--body--hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    background-color: black;
    padding-inline: 5px 5px;
    border-end-end-radius: inherit;
    border-end-start-radius: inherit;
    padding-block: 5px 15px;
    animation-name: cardDescription;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes cardDescription {
    from {
      transform: translateY(100px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .card--title--body,
  .card--description,
  .card--button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .card--title--body {
    height: 20%;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }

  .card--description {
    height: 60%;
    font-size: 12px;
  }

  .card--button {
    height: 15%;
    margin-top: 4%;
    font-size: 15px;
    color: black;
    background-color: beige;
    width: 60%;
    border-radius: 4px;
  }
}

@media (min-width: 1150px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19rem;
    width: 14rem;
    background-color: rgb(5, 34, 34);
    border-radius: 8px;
    margin: 3px;
    margin-inline: 1.1rem 1.1rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.7);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    flex-direction: column;
    height: 19rem;
    width: 14rem;
    background-color: black;
    border-radius: 8px;
    margin: 3px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 1.1rem 1.1rem;
    margin-block: 15px 15px;
    background-image: none;
  }

  .card--title--hover {
    display: none;
  }

  .card--image--hover {
    height: 40%;
    background-color: rgb(5, 34, 34);
    border-start-start-radius: inherit;
    border-start-end-radius: inherit;
    background-size: 100% 100%;
  }

  .card--body--hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    background-color: black;
    padding-inline: 5px 5px;
    border-end-end-radius: inherit;
    border-end-start-radius: inherit;
    padding-block: 5px 15px;
    animation-name: cardDescription;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes cardDescription {
    from {
      transform: translateY(100px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .card--title--body,
  .card--description,
  .card--button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .card--title--body {
    height: 20%;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    text-align: center;
  }

  .card--description {
    height: 60%;
    font-size: 14px;
  }

  .card--button {
    height: 15%;
    margin-top: 4%;
    font-size: 15px;
    color: black;
    background-color: beige;
    width: 60%;
    border-radius: 4px;
  }
}

@media (min-width: 1400px) {
  /*FRONT*/
  .card--product {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24rem;
    width: 17.5rem;
    background-color: rgb(5, 34, 34);
    border-radius: 10px;
    margin: 5px;
    margin-inline: 1.25rem 1.25rem;
    margin-block: 15px 15px;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: inset 50rem 0 rgba(0, 0, 0, 0.6);
  }

  .card--image {
    display: none;
  }

  .card--title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 2px;
    text-align: center;
    padding-inline: 3px 3px;
  }

  .card--body {
    display: none;
  }

  /*HOVER*/
  .card--product--hover {
    display: flex;
    flex-direction: column;
    height: 24rem;
    width: 17.5rem;
    background-color: black;
    border-radius: 10px;
    margin: 5px;
    box-shadow: 1px 1px 5px black;
    overflow: hidden;
    margin-inline: 1.25rem 1.25rem;
    margin-block: 15px 15px;
    background-image: none;
  }

  .card--title--hover {
    display: none;
  }

  .card--image--hover {
    height: 40%;
    background-color: rgb(5, 34, 34);
    border-start-start-radius: inherit;
    border-start-end-radius: inherit;
    background-size: 100% 100%;
    background-position: right;
  }

  .card--body--hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    background-color: black;
    padding-inline: 5px 5px;
    border-end-end-radius: inherit;
    border-end-start-radius: inherit;
    padding-block: 5px 30px;
    animation-name: cardDescription;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes cardDescription {
    from {
      transform: translateY(100px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .card--title--body,
  .card--description,
  .card--button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .card--title--body {
    height: 20%;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
  }

  .card--description {
    height: 60%;
  }

  .card--button {
    height: 15%;
    margin-top: 4%;
    font-size: 15px;
    color: black;
    background-color: beige;
    width: 60%;
    border-radius: 4px;
  }
}
