.landing-page2 {
  background-image: url("../../image/autres/Landing/Rectangle\ 6.png"),
    url("../../image/autres/Fonds/BG_Contact.webp");
  width: 100%;
  height: 99vh;
  margin-top: -6.5em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.degradee {
  display: flex;
  background-image: url("../../image/autres/Landing/Rectangle\ 6.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title-1-contact {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-family: Canela;
}

.body--contain {
  max-width: 100vw;
  padding-inline: 5em 5em;
  padding-block: 5em 5em;
  background-image: url(../../image/autres/Landing/Rectangle\ 51.png);
}

.contact--list {
  background-color: #f2e5d1;
  display: flex;
  justify-content: space-between;
  color: black;
  padding-block: 3em 3em;
  padding-inline: 2em 2em;
  border-radius: 5px 5px 5px 5px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
}

.red--line {
  background-color: #991914;
  width: 0.2em;
  height: 15em;
  border-radius: 2px 2px 2px 2px;
  margin-block: 4em 3em;
}

.faq--title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 2em 2em;
  height: max-content;
  font-family: Canela;
  font-size: 62px;
}

.content {
  width: fit-content;
}

@media screen and (max-width: 1100px) {
  .landing-page2 {
    background-image: url("../../image/autres/Landing/Rectangle\ 6.png"),
      url("../../image/autres/Fonds/BG_Contact.webp");
    width: 100%;
    height: 46vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-1-contact {
    width: 100%;
    margin-block: 2em 2em;
    font-size: 22px !important;
    height: auto;
  }

  .body--contain {
    padding-inline: 2em 2em;
  }

  .contact--list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .red--line {
    display: none !important;
  }

  .list {
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons--img {
    width: 40px;
  }

  .content {
    width: 35%;
  }

  .contact content p {
    font-size: 10px;
  }

  .title {
    margin-block: 0;
  }

  .faq--title {
    font-size: 34px;
  }
}

@media screen and (max-width: 470px) {
  .landing-page2 {
    background-image: url("../../image/autres/Landing/Rectangle\ 6.png"),
      url("../../image/autres/Fonds/BG_Contact.webp");
    width: 100%;
    height: 46vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-1-contact {
    width: 100%;
    margin-block: 2em 2em;
    font-size: 22px !important;
    height: auto;
  }

  .body--contain {
    padding-inline: 2em 2em;
  }

  .contact--list {
    display: flex;
    flex-direction: column;
  }

  .red--line {
    display: none !important;
  }

  .list {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons--img {
    width: 40px;
  }

  .content {
    width: 70%;
    max-width: 35rem;
  }

  .content h3 {
    font-size: 1.15rem;
  }

  .contact content p {
    font-size: 10px;
  }

  .title {
    margin-block: 0;
  }

  .faq--title {
    font-size: 34px;
  }
}
