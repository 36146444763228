.logo--navbar {
  height: 4.5em;
  width: 10.6em;
  padding: 0.6em;
  margin-left: 8em;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../image/autres/logo/LOGO.png);
}

.sidenav select {
  margin-inline: 0.1rem 0.1rem !important;
  width: 5rem;
}

.navbar {
  width: 100%;
  height: 6.5em;
  background: transparent;
}
.scrollingNavbar {
  background: #000;
  transition: 0.3s all;
}
nav {
  /*flex: 1;*/
  text-align: right;
  margin-top: 1em;
}

nav ul li {
  list-style: none;
  display: inline-block;
}

nav ul li a {
  font-family: Raleway;
  text-decoration: none;
  color: #f2e5d1;
  margin-right: 4em;
}

nav ul li a.contact {
  margin-right: 6.5em;
}

nav ul li a:hover {
  text-decoration: none;
  color: white;
}

i.fa-bars {
  display: none;
  color: white;
  width: 25px;
}

/*sidenavvvvvv icone responsive*/
div.sidenav {
  position: fixed;
  top: 6.4em;
  width: 100%;
  border-radius: 0 0 17px 0;
  z-index: 99;
  overflow: hidden;
  overflow-y: scroll;
  height: 0;
  background: darkred;
  transition: 0.6s all;
  display: flex; /* Add this line */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically #212529*/
}

div.sidenav nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
}
div.sidenav nav ul li {
  margin: 0.8em 0; /* Add spacing between menu items */
}

div.sidenav nav ul li a {
  color: #f2e5d1;
  font-family: Canela;
  text-align: center;
  text-decoration: none;
}
div.sidenav nav ul li a:hover {
  color: aliceblue;
  transition: 0.5s all;
}

@media screen and (max-width: 720px) {
  .sidenav a {
    margin-inline: 0.1rem 0.1rem !important;
  }
  .navbar {
    position: sticky;
    top: 0;
    position: relative;
    z-index: 99;
  }
  .logo--navbar {
    padding: 0.6em;
    margin-left: 2em !important;
  }
  i.fa-bars {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 0;
    margin-top: -0.5em !important;
    margin-right: 15px !important;
    transition: 0.5s all;
  }
  i.fa-close {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 0;
    margin-top: -1em !important;
    margin-right: 15px !important;
    transition: 0.5s all;
  }
  div.navbar nav ul li a {
    display: none;
  }
  .nav-links-sidenav li a {
    display: block;
    margin-left: 32px;
  }
}

@media screen and (max-width: 960px) {
  .logo--navbar {
    height: 4em;
    width: 9.6em;
    padding: 0.6em;
    margin-left: 2.5em;
  }

  .translation {
    display: none;
  }
  i.fa-bars {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 0;
    margin-top: -1em !important;
    margin-right: 15px !important;
  }
  nav ul li a {
    display: none;
  }
  .nav-links-sidenav li a {
    display: block;
    margin-left: 32px;
  }
}

@media screen and (max-width: 1105px) {
  .logo--navbar {
    margin-left: 2em;
  }
  nav ul li a {
    margin-right: 2.5em;
  }
}
